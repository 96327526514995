import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../components/buttons/Button";
import { useTheme } from "../theme/theme";
import { Container, Row, Col } from "reactstrap";
import { FaComments, FaBook, FaCogs } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useContent from "../hooks/useContent";

const DEFAULT_PINECONE_PHOTO = "/pinecone-big.png";

const LoggedInHome = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { getContent } = useContent();
  useEffect(() => {
    console.log("Fetching content silently on login...");
    getContent(); // Call getContent without showing a loader
  }, [getContent]);
  // Funksjon for å navigere til spesifikke sider
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Container fluid className="home-page text-center p-2 p-md-5">
      <Row className="text-center align-self-center m-1 m-md-2 pb-md-3">
        <div>
          <img
            src={DEFAULT_PINECONE_PHOTO}
            alt={t("welcome_back")}
            className="logo"
          />
          <h1 className="fs-1 text-black fw-bold">KONGLA</h1>
        </div>
      </Row>

      <Row
        className="home-card text-center p-2 p-md-5 m-1"
        style={{
          background:
            theme === "light"
              ? "var(--background-gradient-light)"
              : "var(--background-gradient-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <div className="pt-3 pb-3">
          <h2>{t("welcome")}</h2>
          <p className="m-md-5 fs-6 p-2 ps-md-5 pe-md-5 ">
            {t("logged_in_message")}
          </p>

          {/* Hurtigknapper */}
          <Row className="mt-4">
            <Col md="4" className="d-flex justify-content-center mb-2">
              <PrimaryButton
                label={t("create_new_dialog")}
                icon={FaComments}
                className="w-100"
                onClick={() => handleNavigation("/dialog")}
              />
            </Col>
            <Col md="4" className="d-flex justify-content-center mb-2">
              <PrimaryButton
                label={t("add_new_content")}
                icon={FaBook}
                className="w-100"
                onClick={() => handleNavigation("/content")}
              />
            </Col>
            <Col md="4" className="d-flex justify-content-center mb-2">
              <PrimaryButton
                label={t("add_new_context")}
                icon={FaCogs}
                className="w-100"
                onClick={() => handleNavigation("/context")}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </Container>
  );
};

export default LoggedInHome;
