import React from "react";
import { ListGroupItem } from "reactstrap";
import { useTheme } from "../../theme/theme";
import { formatDate } from "../../utils/dateUtils";
import "./ContentListItem.css";

const ContentListItem = ({ content, onSelectContent, isSelected }) => {
  const { theme } = useTheme();

  return (
    <ListGroupItem
      onClick={() => onSelectContent(content)}
      style={{
        backgroundColor:
          theme === "light"
            ? "var(--background-color)"
            : "var(--background-color-darker)",
        color: theme === "light" ? "var(--dark)" : "var(--light)",
      }}
      className={`d-flex justify-content-between align-items-center content-list-item ${
        isSelected ? "selected" : ""
      }`}
    >
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="content-details">
          <h5 className="mb-2">{content.title}</h5>
          <p className="mb-2">
            {content.description.length > 40
              ? `${content.description.slice(0, 40)}...`
              : content.description}
          </p>{" "}
          <p className="text-end">{formatDate(content.updated)}</p>
        </div>
      </div>
    </ListGroupItem>
  );
};

export default ContentListItem;
