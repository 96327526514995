import React, { useEffect, useState } from "react";
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle, FaTimes } from "react-icons/fa";
import GenericList from "../genericList/GenericList";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import InformationModal from "../informationModal/InformationModal";
import "./BetterClassificationInfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ButtonGroup from "../buttons/ButtonGroup";
import { CancelButton, ExportButton, QuestionButton } from "../buttons/Button";

const BetterClassificationInfo = ({
  isLoading,
  classificationData,
  content,
  selectedFile,
  onClose,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("fields");
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    console.log("ExpandedRowId updated to:", expandedRowId);
  }, [expandedRowId]);

  // Generer unike ID-er
  const generateUniqueId = (prefix, index, category, description) => {
    const safeCategory = String(category || "unknown-category").replace(
      /[^a-zA-Z0-9]/g,
      "-"
    );
    const safeDescription = String(
      Array.isArray(description)
        ? description.join(", ")
        : description || "no-description"
    ).replace(/[^a-zA-Z0-9]/g, "-");
    return `${prefix}-${index}-${safeCategory}-${safeDescription}`;
  };

  // Map data fra classificationData
  const fieldsData = (classificationData?.fields || []).map((item, index) => ({
    id: generateUniqueId(
      "field",
      index,
      item.category,
      item.valueAsString || item.value
    ),
    category: item.category || t("Unknown Category"),
    description: Array.isArray(item.value)
      ? item.value.join(", ")
      : item.value || t("No description available"),
    confidenceScore: (item.confidenceScore || 0) * 100,
    explanations: item.reason || t("No explanation available"),
  }));

  const unmatchedData = (classificationData?.unmatchedFields || []).map(
    (item, index) => ({
      id: generateUniqueId(
        "unmatched-field",
        index,
        item.category,
        item.valueAsString || item.value
      ),
      category: item.category || t("Unknown Category"),
      description: Array.isArray(item.value)
        ? item.value.join(", ")
        : item.value || t("No description available"),
      confidenceScore: (item.confidenceScore || 0) * 100,
      explanations: item.reason || t("No explanation available"),
    })
  );

  const excludedData = (classificationData?.excludedFields || []).map(
    (item, index) => ({
      id: generateUniqueId(
        "excluded-field",
        index,
        item.category,
        item.valueAsString || item.value
      ),
      category: item.category || t("Unknown Category"),
      description: Array.isArray(item.value)
        ? item.value.join(", ")
        : item.value || t("No description available"),
      confidenceScore: (item.confidenceScore || 0) * 100,
      explanations: item.reason || t("No explanation available"),
    })
  );

  const tableDescriptions = {
    fields:
      "Displays the fields that were successfully classified with sufficient confidence. Each field includes its category, value, confidence score, and explanation, indicating why it was included in the final classification results.",
    unmatchedFields:
      "Shows the fields that could not be matched to any classification category. These fields either lacked sufficient information or could not be confidently assigned to an existing category.",
    excludedFields:
      "Contains the fields that were excluded due to low confidence. These fields were identified but did not meet the threshold for inclusion in the final classification results.",
  };

  const modalContent = (
    <>
      <p>{t("modals.classificationProcess.description")}</p>
      <ul>
        <li>
          <FontAwesomeIcon icon={faCheckCircle} className="icon success" />
          <strong>{t("Fields")}:</strong>{" "}
          {t("modals.classificationProcess.categories.fields")}
        </li>
        <li>
          <FontAwesomeIcon icon={faQuestionCircle} className="icon info" />
          <strong>{t("Unmatched Fields")}:</strong>{" "}
          {t("modals.classificationProcess.categories.unmatchedFields")}
        </li>
        <li>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="icon warning"
          />
          <strong>{t("Excluded Fields")}:</strong>{" "}
          {t("modals.classificationProcess.categories.excludedFields")}
        </li>
      </ul>
      <p>{t("modals.classificationProcess.goal")}</p>
    </>
  );

  // Organiser data per tab
  const dataByTab = {
    fields: fieldsData,
    unmatchedFields: unmatchedData,
    excludedFields: excludedData,
  };

  // Filtrer og sorter data
  const filteredData =
    dataByTab[activeTab]?.filter(
      (item) =>
        item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const sortedData = [...filteredData].sort(
    (a, b) => b.confidenceScore - a.confidenceScore
  );

  const exportAllToPDF = async () => {
    const doc = new jsPDF();

    // 1. Legg til logo
    const img = new Image();
    img.src = `${window.location.origin}/pinecone-big.png`;
    img.onload = () => {
      const imgWidth = 25;
      const aspectRatio = img.width / img.height;
      const imgHeight = imgWidth / aspectRatio;
      const imgX = 10;
      const imgY = 20;

      doc.addImage(img, "PNG", imgX, imgY, imgWidth, imgHeight);

      // 2. Rapportoverskrift
      doc.setFontSize(16);
      doc.text("Classification Report", imgX + imgWidth + 10, imgY + 15);

      // 3. Genereringsdato
      const currentDate = new Date().toLocaleString();
      doc.setFontSize(10);
      doc.text(`Generated on: ${currentDate}`, imgX + imgWidth + 10, imgY + 25);

      // 4. Informasjon om valgt fil
      const sectionStartY = imgY + imgHeight + 30;
      doc.setFontSize(14);
      doc.text("Selected File Information:", 20, sectionStartY);

      doc.setFontSize(10);
      const selectedFileText = `Filename: ${selectedFile}`;
      const wrappedFileText = doc.splitTextToSize(selectedFileText, 170);
      doc.text(wrappedFileText, 25, sectionStartY + 8);

      // 5. Informasjon om content
      const contentStartY = sectionStartY + 25;
      doc.setFontSize(14);
      doc.text("Content Information:", 20, contentStartY);

      doc.setFontSize(10);
      const contentTexts = [
        `ID: ${content?.id || "N/A"}`,
        `Title: ${content?.title || "N/A"}`,
        `Description: ${content?.description || "No description provided"}`,
      ];
      contentTexts.forEach((text, index) => {
        const wrappedText = doc.splitTextToSize(text, 170);
        doc.text(wrappedText, 25, contentStartY + 8 + index * 10);
      });

      // 6. Klassifiseringsdata
      const tableStartY = contentStartY + 60; // Økt mellomrom før tabellen
      doc.setFontSize(14);
      doc.text("Classification Data", 20, tableStartY - 12); // Flyttet opp for klarhet
      doc.autoTable({
        startY: tableStartY,
        margin: { top: 10 }, // Ekstra avstand fra overskriften
        head: [["Category", "Description", "Confidence Score"]],
        body: fieldsData.map((item) => [
          item.category,
          doc.splitTextToSize(item.description, 60).join("\n"),
          `${item.confidenceScore.toFixed(2)}%`,
        ]),
      });

      // 7. Unmatched Fields
      const unmatchedStartY = doc.lastAutoTable.finalY + 20;
      doc.setFontSize(14);
      doc.text("Unmatched Fields", 20, unmatchedStartY - 12); // Flyttet opp for klarhet
      doc.autoTable({
        startY: unmatchedStartY,
        margin: { top: 10 },
        head: [["Category", "Description", "Confidence Score"]],
        body: unmatchedData.map((item) => [
          item.category,
          doc.splitTextToSize(item.description, 60).join("\n"),
          `${item.confidenceScore.toFixed(2)}%`,
        ]),
      });

      // 8. Excluded Fields
      const excludedStartY = doc.lastAutoTable.finalY + 20;
      doc.setFontSize(14);
      doc.text("Excluded Fields", 20, excludedStartY - 12); // Flyttet opp for klarhet
      doc.autoTable({
        startY: excludedStartY,
        margin: { top: 10 },
        head: [["Category", "Description", "Confidence Score"]],
        body: excludedData.map((item) => [
          item.category,
          doc.splitTextToSize(item.description, 60).join("\n"),
          `${item.confidenceScore.toFixed(2)}%`,
        ]),
      });

      // 9. Lagre PDF med dynamisk filnavn
      const contentId = content?.id || "unknown-content";
      const selectedFileName =
        selectedFile?.replace(/[^a-zA-Z0-9-_]/g, "_") || "unknown-file";
      doc.save(`classification-report-${contentId}-${selectedFileName}.pdf`);
    };
  };

  // Render liste for tab
  const renderList = () => {
    if (sortedData.length === 0) {
      return (
        <Alert color="info" className="text-center">
          {t("No data available for the selected tab.")}
        </Alert>
      );
    }

    return (
      <GenericList
        loading={isLoading}
        data={sortedData}
        columns={[
          { key: "category", label: t("Category") },
          { key: "description", label: t("Description") },
          {
            key: "confidenceScore",
            label: t("Confidence Score"),
            render: (item) => `${item.confidenceScore.toFixed(2)}%`,
          },
        ]}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        expandedRow={expandedRowId}
        rowEqualityCheck={(row1, row2) => row1 === row2.id}
        onRowClick={(item) =>
          setExpandedRowId((prevId) => (prevId === item.id ? null : item.id))
        }
        toggleExpandedRow={(item) => (
          <p>{item.explanations || t("No explanations provided")}</p>
        )}
        noResultsMessage={t("No classification data available")}
        tableDescription={tableDescriptions[activeTab]}
      />
    );
  };

  // Håndter tab-skifte
  const handleTabChange = (tab) => {
    setExpandedRowId(null);
    setActiveTab(tab);
  };

  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    if (classificationData?.error) {
      setVisible(true); // Reset alert visibility when a new error appears
    }
  }, [classificationData?.error]); // Depend on error changes
  if (isLoading) {
    return <LoadingOverlay isLoading={isLoading} />;
  }

  // Hvis ingen data er tilgjengelig
  if (!classificationData || Object.keys(classificationData).length === 0) {
    return null;
  }
  const isDataCorrupt =
    !classificationData?.fields ||
    typeof classificationData.fields !== "object" ||
    Object.keys(classificationData.fields).length === 0;
  // Hvis det er en generell feil i dataene
  if (classificationData?.error || isDataCorrupt) {
    return (
      <Alert
        color="danger"
        isOpen={visible}
        toggle={onDismiss}
        className="text-center"
      >
        {t("An error occurred during processing. Please try again later.")}
      </Alert>
    );
  }

  // Returner hele komponenten
  return (
    <div className="classification-info">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="classification-title">
          {t("Classification Information")}
        </h4>
        <ButtonGroup
          buttons={[
            <QuestionButton
              icon={FaQuestionCircle}
              onClick={toggleModal}
              label={t("How it works")}
            />,
            <ExportButton onExportPDF={exportAllToPDF} />,
            <CancelButton
              icon={FaTimes}
              onClick={onClose}
              label={t("Close")}
            />,
          ]}
        />
      </div>
      {selectedFile && (
        <p className="selected-file">
          <strong>{t("Selected File")}:</strong> {selectedFile}
        </p>
      )}
      <InformationModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        title={t("modals.classificationProcess.title")}
        content={modalContent}
        modalClassName="information-modal"
      />
      <Nav tabs>
        {["fields", "unmatchedFields", "excludedFields", "graphs"].map(
          (tab) => (
            <NavItem key={tab}>
              <NavLink
                className={classnames({ active: activeTab === tab })}
                onClick={() => handleTabChange(tab)}
              >
                {t(tab.charAt(0).toUpperCase() + tab.slice(1))}
              </NavLink>
            </NavItem>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTab} className="mt-3">
        <TabPane tabId={activeTab}>{renderList()}</TabPane>
      </TabContent>
    </div>
  );
};

export default BetterClassificationInfo;
