import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  FaPlus,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useContent from "../hooks/useContent";
import ContentForm from "../components/content/ContentForm";
import ContentList from "../components/content/ContentList";
import ContentInfo from "../components/content/ContentInfo";
import PrimaryButton from "../components/buttons/Button";
import "./Content.css";

const Content = () => {
  const { contentId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { content, loading, selectedContent, setSelectedContent, getContent } =
    useContent(contentId);

  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isContentListVisible, setIsContentListVisible] = useState(true);
  const [isTable, setIsTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    setIsAtBottom(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsAtBottom(false);
  };

  const handleScroll = () => {
    const isNearBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 50;
    setIsAtBottom(isNearBottom);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (contentId && content.length > 0) {
      const selected = content.find((item) => item.id === contentId);
      if (selected && selected.id !== selectedContent?.id) {
        setSelectedContent(selected);
      }
    } else if (!contentId) {
      setSelectedContent(null);
    }
  }, [contentId, content, setSelectedContent, selectedContent]);

  useEffect(() => {
    if (selectedContent && selectedContent.id !== contentId) {
      navigate(`/content/${selectedContent.id}`, { replace: true });
    }
  }, [selectedContent, navigate, contentId]);

  const handleContentSelect = useCallback(
    (item) => {
      if (item.id !== contentId) {
        if (showEditForm || showAddForm) {
          // Open ContentInfo in a new separate window
          const windowFeatures = `
            width=800,
            height=600,
            top=${window.screenY + 50},
            left=${window.screenX + 50},
            toolbar=no,
            menubar=no,
            scrollbars=yes,
            resizable=yes,
            location=no,
            status=no
          `;
          window.open(`/content/${item.id}`, "_blank", windowFeatures.trim());
        } else {
          setSelectedContent(item);
          navigate(`/content/${item.id}`, { replace: true });

          if (window.innerWidth <= 768) {
            setIsContentListVisible(false);
          }
        }
      }
    },
    [contentId, setSelectedContent, navigate, showEditForm, showAddForm]
  );

  const handleAddNewContent = () => {
    setSelectedContent(null);
    navigate(`/content`);
    setShowAddForm(true);
    setShowEditForm(false);
    setIsTable(false);
    setTableData([]);
  };

  const handleEditContent = () => {
    if (selectedContent.text && isValidTableFormat(selectedContent.text)) {
      setIsTable(true);
      setTableData(JSON.parse(selectedContent.text));
    } else {
      setIsTable(false);
    }
    setShowEditForm(true);
    setShowAddForm(false);
  };

  const isValidTableFormat = (text) => {
    try {
      const parsed = JSON.parse(text);
      return Array.isArray(parsed) && parsed.every(Array.isArray);
    } catch (e) {
      return false;
    }
  };

  const handleSuccess = useCallback(
    (newContent) => {
      setSelectedContent(newContent);
      setShowAddForm(false);
      setShowEditForm(false);
      setIsTable(false);
      setTableData([]);
      getContent();

      if (newContent && newContent.id) {
        navigate(`/content/${newContent.id}`, { replace: true });
      } else {
        navigate(`/content/${contentId}`, { replace: true });
      }
    },
    [getContent, setSelectedContent, navigate, contentId]
  );

  const handleCancel = () => {
    setShowAddForm(false);
    setShowEditForm(false);
    setIsTable(false);
    setTableData([]);
  };

  const toggleContentList = () => {
    setIsContentListVisible((prev) => !prev);
  };

  return (
    <Container fluid className="content-container min-vh-100">
      <button className="toggle-button" onClick={toggleContentList}>
        {isContentListVisible ? <FaChevronLeft /> : <FaChevronRight />}
      </button>

      <Row>
        {isContentListVisible && (
          <Col
            md="4"
            xl="3"
            className={`d-flex flex-column contentlist-slide ${
              isContentListVisible ? "" : "contentlist-slide-hidden"
            }`}
          >
            <ContentList
              content={content}
              onSelectContent={handleContentSelect}
              loading={loading}
              selectedContent={selectedContent}
            />
          </Col>
        )}
        <Col
          md={isContentListVisible ? "8" : "12"}
          xl={isContentListVisible ? "9" : "12"}
          className="d-flex flex-column"
        >
          <div className="scroll-buttons">
            {!isAtBottom && (
              <button className="scroll-up" onClick={scrollToTop}>
                <FaChevronUp />
              </button>
            )}
            <button
              className="scroll-down"
              onClick={scrollToBottom}
              style={{ display: isAtBottom ? "none" : "block" }}
            >
              <FaChevronDown />
            </button>
          </div>

          <Row className="add-button-container">
            <div className="text-end">
              <PrimaryButton
                label={t("add_new_content")}
                icon={FaPlus}
                onClick={handleAddNewContent}
              />
            </div>
          </Row>
          {selectedContent && !showEditForm ? (
            <ContentInfo
              onSuccess={handleSuccess}
              content={selectedContent}
              onEdit={handleEditContent}
            />
          ) : showAddForm ? (
            <ContentForm
              content={null}
              onSuccess={handleSuccess}
              onCancel={handleCancel}
              isTable={isTable}
              tableData={tableData}
            />
          ) : showEditForm ? (
            <ContentForm
              content={selectedContent}
              onSuccess={handleSuccess}
              onCancel={handleCancel}
              isTable={isTable}
              tableData={tableData}
            />
          ) : (
            <div className="empty-state">
              <h4>{t("select_or_add_content")}</h4>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Content;
