import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./authConfig";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Create MSAL instance outside the component to prevent re-creation on re-renders
const msalInstance = new PublicClientApplication(msalConfig);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();

  // Helper function to check if a token is valid
  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return false;
    }
  };

  // Logout function
  const logout = useCallback(async () => {
    try {
      await msalInstance.logoutPopup();
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }, [navigate]);

  // MSAL Initialization
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        const response = await msalInstance.handleRedirectPromise();

        if (response !== null) {
          msalInstance.setActiveAccount(response.account);
          setIsAuthenticated(true);
        } else {
          const account =
            msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
          if (account) {
            msalInstance.setActiveAccount(account);
            const tokenResponse = await msalInstance.acquireTokenSilent({
              ...loginRequest,
              account,
            });

            if (isTokenValid(tokenResponse.idToken)) {
              setIsAuthenticated(true);
            } else {
              await logout();
            }
          } else {
            setIsAuthenticated(false);
          }
        }
      } catch (error) {
        console.error("Error during MSAL initialization:", error);
        setIsAuthenticated(false);
      } finally {
        setIsInitialized(true);
      }
    };

    initializeMsal();
  }, [logout]);

  // Login function
  const login = async () => {
    try {
      await msalInstance.loginPopup(loginRequest);
      const account =
        msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
      if (account) {
        msalInstance.setActiveAccount(account);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // Fetch ID Token
  const getIdToken = async () => {
    if (!isInitialized) {
      console.error("MSAL is not initialized");
      return null;
    }

    const account = msalInstance.getActiveAccount();
    if (!account) {
      console.error("No active account found");
      return null;
    }

    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      if (!isTokenValid(response.idToken)) {
        throw new Error("Token expired");
      }

      return response.idToken;
    } catch (error) {
      console.error("Failed to acquire ID token silently:", error);

      if (
        error instanceof InteractionRequiredAuthError ||
        error.message === "Token expired"
      ) {
        try {
          const response = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account,
          });

          if (!isTokenValid(response.idToken)) {
            throw new Error("Token expired");
          }

          return response.idToken;
        } catch (popupError) {
          console.error("Failed to acquire ID token with popup:", popupError);
          await logout();
          return null;
        }
      } else {
        await logout();
        return null;
      }
    }
  };

  // Fetch User ID from ID Token
  const getUserId = async () => {
    try {
      const idToken = await getIdToken();
      if (idToken) {
        const decodedToken = jwtDecode(idToken);
        return decodedToken.oid; // Return Azure AD Object ID
      }
      throw new Error("Unable to fetch user ID");
    } catch (error) {
      console.error("Error fetching user ID:", error);
      throw error;
    }
  };

  // Fetch Access Token
  const getAccessToken = async () => {
    if (!isInitialized) {
      console.error("MSAL is not initialized");
      return null;
    }

    const account = msalInstance.getActiveAccount();
    if (!account) {
      console.error("No active account found");
      return null;
    }

    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      if (!isTokenValid(response.accessToken)) {
        throw new Error("Token expired");
      }

      return response.accessToken;
    } catch (error) {
      console.error("Failed to acquire access token silently:", error);

      if (
        error instanceof InteractionRequiredAuthError ||
        error.message === "Token expired"
      ) {
        try {
          const response = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account,
          });

          if (!isTokenValid(response.accessToken)) {
            throw new Error("Token expired");
          }

          return response.accessToken;
        } catch (popupError) {
          console.error(
            "Failed to acquire access token with popup:",
            popupError
          );
          await logout();
          return null;
        }
      } else {
        await logout();
        return null;
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        instance: msalInstance,
        isAuthenticated,
        isInitialized,
        login,
        logout,
        getIdToken,
        getAccessToken,
        getUserId,
      }}
    >
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </AuthContext.Provider>
  );
};

// Hooks for authentication state
export const useAuth = () => useContext(AuthContext);
export const useAuthStatus = () => useAuth().isAuthenticated;
export const useAuthInitialization = () => useAuth().isInitialized;
