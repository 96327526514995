import React, { useReducer, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardText,
  Table,
} from "reactstrap";
import {
  FaRegFileAlt,
  FaAlignLeft,
  FaTag,
  FaLink,
  FaEdit,
  FaPlus,
  FaTags,
} from "react-icons/fa";
import "./ContentInfo.css";
import { useTheme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "../feedbackModal/FeedbackModal";
import { DeleteModal } from "../deleteModal/DeleteModal";
import { DropdownActions } from "../dropdownAction/DropdownAction";
import useContent from "../../hooks/useContent";
import useClassify from "../../hooks/useClassify";
import ClassificationInfo from "../classification/ClassificationInfo";
import BetterClassificationInfo from "../classification/BetterClassificationInfo";
import ClassificationSelector from "../classification/ClassificationSelector";
import ReactMarkdown from "react-markdown";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "reactstrap";
const initialState = {
  isLoading: false,
  statusMessage: "",
  classificationData: null,
  betterClassificationData: null, // Ny tilstand for Classify v2
  selectedFile: "",
  feedbackMessage: "",
  feedbackModalOpen: false,
  feedbackColor: "success",
  showClassificationInfo: false,
  showBetterClassificationInfo: false, // Ny tilstand for å vise Classify v2-tabellen
  classifyModalOpen: false,
  betterClassifyModalOpen: false, // Ny tilstand for Classify v2-meny
  modalOpen: false,
  showCopyAlert: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "START":
      return {
        ...state,
        isLoading: true,
        selectedFile: action.payload.fileName,
        statusMessage: "Processing started...",
        showClassificationInfo: action.payload.type === "classify",
        showBetterClassificationInfo: action.payload.type === "better-classify",
      };
    case "SET_MESSAGE":
      return { ...state, statusMessage: action.payload };
    case "SET_DATA":
      return {
        ...state,
        classificationData: action.payload,
        isLoading: false,
        statusMessage: "Processing complete!",
      };
    case "SHOW_CLASSIFICATION_INFO":
      return {
        ...state,
        showClassificationInfo: true,
        showBetterClassificationInfo: false,
      };
    case "SHOW_BETTER_CLASSIFICATION_INFO":
      return {
        ...state,
        showBetterClassificationInfo: true,

        showClassificationInfo: false,
      };
    case "SET_BETTER_DATA":
      return {
        ...state,
        betterClassificationData: action.payload,
        isLoading: false,
        statusMessage: "Processing complete!",
      };
    case "RESET":
      return initialState;
    case "RESET_CLASSIFICATION_INFO":
      return {
        ...state,
        classificationData: null,
        selectedFile: "",
        showClassificationInfo: false,
      };
    case "RESET_BETTER_CLASSIFICATION_INFO":
      return {
        ...state,
        betterClassificationData: null,
        showBetterClassificationInfo: false,
      };
    case "TOGGLE_MODAL":
      return { ...state, modalOpen: !state.modalOpen };
    case "TOGGLE_CLASSIFY_MODAL":
      return {
        ...state,
        classifyModalOpen: !state.classifyModalOpen,
        showClassificationInfo: true,
      };
    case "TOGGLE_BETTER_CLASSIFY_MODAL":
      return {
        ...state,
        betterClassifyModalOpen: !state.betterClassifyModalOpen,
      };
    case "SET_FEEDBACK":
      return {
        ...state,
        feedbackMessage: action.payload.message,
        feedbackColor: action.payload.color,
        feedbackModalOpen: true,
      };
    case "CLOSE_FEEDBACK":
      return { ...state, feedbackModalOpen: false };
    case "SHOW_COPY_ALERT":
      return { ...state, showCopyAlert: true };
    case "HIDE_COPY_ALERT":
      return { ...state, showCopyAlert: false };
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        classificationData: { error: action.payload },
        statusMessage: "An error occurred.",
      };
    default:
      return state;
  }
};

const ContentInfo = ({ content, onEdit, onSuccess }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const classify = useClassify("classify"); // For standard klassifisering
  const betterClassify = useClassify("better-classify"); // For bedre klassifisering
  const { deleteContentItem } = useContent();
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    dispatch({ type: "RESET_CLASSIFICATION_INFO" });
  }, [content]);

  useEffect(() => {
    if (state.feedbackModalOpen) {
      const timer = setTimeout(
        () => dispatch({ type: "CLOSE_FEEDBACK" }),
        3000
      );
      return () => clearTimeout(timer);
    }
  }, [state.feedbackModalOpen]);

  useEffect(() => {
    if (state.elapsedTime >= 10 && state.elapsedTime < 60) {
      dispatch({
        type: "SET_MESSAGE",
        payload: "Processing is taking longer than expected...",
      });
    } else if (state.elapsedTime >= 60 && state.elapsedTime < 120) {
      dispatch({
        type: "SET_MESSAGE",
        payload:
          "This might take up to 2 minutes. Thank you for your patience.",
      });
    } else if (state.elapsedTime >= 120) {
      dispatch({
        type: "SET_MESSAGE",
        payload:
          "This is taking unusually long. You can wait or try again later.",
      });
    }
  }, [state.elapsedTime]);

  const handleFileSelect = async (fileName, type = "classify") => {
    dispatch({ type: "START", payload: { fileName, type } }); // Passer både fileName og type

    if (type === "classify") {
      dispatch({ type: "TOGGLE_CLASSIFY_MODAL" });
    } else if (type === "better-classify") {
      dispatch({ type: "TOGGLE_BETTER_CLASSIFY_MODAL" });
    }

    try {
      const updatedContent = { ...content, classificationBlobName: fileName };

      const classifyFunction =
        type === "classify"
          ? classify.classifyCategoryData
          : betterClassify.classifyCategoryData;

      const data = await classifyFunction({ content: updatedContent });

      if (type === "classify") {
        dispatch({ type: "SET_DATA", payload: data });
        dispatch({ type: "SHOW_CLASSIFICATION_INFO" });
      } else {
        dispatch({ type: "SET_BETTER_DATA", payload: data });
        dispatch({ type: "SHOW_BETTER_CLASSIFICATION_INFO" });
      }
    } catch (error) {
      console.error("Error classifying content:", error);
      dispatch({
        type: "SET_MESSAGE",
        payload: "An error occurred while processing. Please try again.",
      });
      dispatch({
        type: type === "classify" ? "SET_DATA" : "SET_BETTER_DATA",
        payload: { error: error.response || error.message || "Unknown error" },
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteContentItem(content.id);
      dispatch({
        type: "SET_FEEDBACK",
        payload: { message: t("delete_success"), color: "success" },
      });
      setTimeout(() => onSuccess(), 3000);
    } catch (error) {
      console.error("Error deleting content:", error);
      dispatch({
        type: "SET_FEEDBACK",
        payload: { message: t("delete_content_error"), color: "danger" },
      });
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        dispatch({ type: "SHOW_COPY_ALERT" });
        setTimeout(() => dispatch({ type: "HIDE_COPY_ALERT" }), 2000);
      })
      .catch((err) => {
        console.error("Error copying URL:", err);
        dispatch({
          type: "SET_FEEDBACK",
          payload: { message: t("copy_failed"), color: "danger" },
        });
      });
  };

  const handleCreateThread = () => {
    const initialContentData = {
      contentIds: [content.id],
      contentTitles: [content.title],
    };
    navigate("/dialog", { state: initialContentData });
  };

  return (
    <Container className="content-info">
      <Card
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--card-background-color)"
              : "var(--card-background-color-dark)",
          color: theme === "light" ? "var(--dark)" : "var(--light)",
        }}
      >
        <DropdownActions
          handlePrimaryAction={handleCreateThread}
          handleCopyUrl={handleCopyUrl}
          toggleDeleteModal={() => dispatch({ type: "TOGGLE_MODAL" })}
          showCopyAlert={state.showCopyAlert}
          primaryActionIcon={FaPlus}
          primaryActionLabel={t("create_thread")}
          secondaryActions={[
            {
              onClick: () => dispatch({ type: "TOGGLE_CLASSIFY_MODAL" }),
              icon: FaTags,
              label: t("classify_content"),
            },
            {
              onClick: () => dispatch({ type: "TOGGLE_BETTER_CLASSIFY_MODAL" }),
              icon: FaTags,
              label: t("better_classify_content"),
            },
            {
              onClick: onEdit,
              icon: FaEdit,
              label: t("edit"),
            },
          ]}
        />

        <CardBody>
          {state.showClassificationInfo && (
            <ClassificationInfo
              isLoading={state.isLoading}
              classificationData={state.classificationData}
              selectedFile={state.selectedFile}
              onClose={() => dispatch({ type: "RESET_CLASSIFICATION_INFO" })}
            />
          )}
          {state.showBetterClassificationInfo && (
            <BetterClassificationInfo
              isLoading={state.isLoading}
              classificationData={state.betterClassificationData}
              selectedFile={state.selectedFile}
              content={content}
              onClose={() =>
                dispatch({ type: "RESET_BETTER_CLASSIFICATION_INFO" })
              }
            />
          )}
          <CardTitle tag="h1" className="text-center mb-4">
            {content.title}
          </CardTitle>
          <Row>
            <Col xl="3">
              <ContentInfoItem
                content={content.type}
                label={t("type")}
                icon={FaTag}
              />
            </Col>
            <Col xl="9">
              <ContentInfoItem
                url={content.url}
                label={t("url")}
                icon={FaLink}
              />
            </Col>
          </Row>
          <ContentInfoItem
            content={content.description}
            label={t("system_instruction")}
            icon={FaRegFileAlt}
            name="description"
            id="description"
          />
          <ContentInfoItem
            content={content.text}
            label={t("text")}
            icon={FaAlignLeft}
          />
        </CardBody>
      </Card>
      <ClassificationSelector
        isOpen={state.classifyModalOpen}
        toggle={() => dispatch({ type: "TOGGLE_CLASSIFY_MODAL" })}
        onSelect={(fileName) => handleFileSelect(fileName, "classify")}
      />

      <ClassificationSelector
        isOpen={state.betterClassifyModalOpen}
        toggle={() => dispatch({ type: "TOGGLE_BETTER_CLASSIFY_MODAL" })}
        onSelect={(fileName) => handleFileSelect(fileName, "better-classify")}
      />
      <DeleteModal
        isOpen={state.modalOpen}
        toggle={() => dispatch({ type: "TOGGLE_MODAL" })}
        handleDelete={handleDelete}
      />
      <FeedbackModal
        isOpen={state.feedbackModalOpen}
        message={state.feedbackMessage}
        color={state.feedbackColor}
      />
    </Container>
  );
};

export const ContentInfoItem = ({
  content,
  label,
  name,
  icon: Icon,
  url,
  id,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const renderTable = (json) => {
    try {
      const tableData = JSON.parse(json);
      if (Array.isArray(tableData)) {
        return (
          <div className="table-container styled-table markdown-content">
            <Table striped bordered responsive hover>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) => (
                      <td key={colIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        );
      }
    } catch {
      return <p>Invalid table data</p>;
    }
  };

  const renderDocLines = (json) => {
    try {
      const lines = JSON.parse(json);
      if (Array.isArray(lines)) {
        return (
          <ul className="markdown-content">
            {lines.map((line, index) => (
              <ReactMarkdown>
                <li key={index}>{line}</li>
              </ReactMarkdown>
            ))}
          </ul>
        );
      }
    } catch {
      return <p>Invalid document content</p>;
    }
  };

  return (
    <div className="align-items-center p-3">
      <div className="d-flex">
        <div className="me-2">
          <Icon className="info-icon" />
        </div>
        <div>
          <h5
            style={{
              color:
                theme === "light" ? "var(--primary)" : "var(--primary-dark)",
            }}
          >
            {label}:
            {name === "description" && (
              <>
                <FaInfoCircle
                  id={`tooltip-${id}`}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={`tooltip-${id}`}
                  toggle={() => setTooltipOpen(!tooltipOpen)}
                >
                  {t("system_instruction_tooltip")}
                </Tooltip>
              </>
            )}
          </h5>
        </div>
      </div>
      <div className="px-5">
        <CardText>
          {content && content.startsWith("[") ? ( // Check if content is JSON
            Array.isArray(JSON.parse(content)) ? (
              renderTable(content) // Render as table
            ) : (
              renderDocLines(content)
            ) // Render as list
          ) : (
            <div className="markdown-content">
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
          )}
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-break"
            >
              {url}
            </a>
          )}
        </CardText>
      </div>
    </div>
  );
};

export default ContentInfo;
