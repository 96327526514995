import React, { useState, useEffect } from "react";
import { Form, Badge, Collapse } from "reactstrap";
import ContentModal from "../content/ContentModal";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./NewDialogForm.css";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/theme";
import PrimaryButton, {
  OptionButton,
  SecondaryButton,
} from "../buttons/Button";
import { FormField } from "../content/ContentForm";

const NewDialogForm = ({
  onAddThread,
  initialContentId,
  initialContentTitles,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedContent, setSelectedContent] = useState([]);
  // const [ setContentTitles] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { theme } = useTheme();

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newThread = {
      title: title || "Title",
      description: description || "Description",
      context: selectedContent.map((item) => ({
        contentId: item.id, // Access the 'id' of the item
        type: "manually_selected",
      })),
    };
    onAddThread(newThread);
    setTitle("");
    setDescription("");
    setSelectedContent([]);
    // setContentTitles({});
    setIsOpen(false); // Collapse the form after submission
  };

  const handleContentSelect = (contentIds, contentList) => {
    const selectedItems = contentIds.map((id) => ({
      id,
      title: contentList.find((content) => content.id === id).title,
    }));
    setSelectedContent(selectedItems);

    const titles = {};
    selectedItems.forEach((content) => {
      titles[content.id] = content.title;
    });
    // setContentTitles(titles);
  };

  useEffect(() => {
    if (initialContentId.length > 0) {
      const contentList = initialContentId.map((id, index) => ({
        id,
        title: initialContentTitles[index],
      }));
      setSelectedContent(contentList);
      setIsOpen(true); // Automatically open the form if there's an initial content ID
    }
  }, [initialContentId, initialContentTitles]);

  return (
    <div className="new-thread-form-container">
      <div className="text-end">
        <PrimaryButton
          type="button"
          color="primary"
          onClick={toggleCollapse}
          className="mb-3"
          icon={isOpen ? FaMinus : FaPlus}
          label={isOpen ? t("hide_form") : t("create_new_dialog")}
        />
      </div>

      <Collapse isOpen={isOpen}>
        <div
          className="new-thread-form"
          style={{
            backgroundColor: theme === "light" ? "#ffffff" : "#000000",
            color: theme === "light" ? "#000000" : "#ffffff",
          }}
        >
          <h3
            className="text-uppercase"
            style={{
              color:
                theme === "light" ? "var(--primary)" : "var(--primary-dark)",
            }}
          >
            {t("create_new_dialog")}{" "}
          </h3>
          <Form onSubmit={handleSubmit}>
            <FormField
              type="text"
              name="title"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
              label={t("title")}
              style={{
                backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                color: theme === "light" ? "#000000" : "#ffffff",
              }}
            />
            <FormField
              type="textarea"
              name="description"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              style={{
                backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                color: theme === "light" ? "#000000" : "#ffffff",
              }}
              label={t("system_instruction")}
            />

            <OptionButton
              type="button"
              color="primary"
              onClick={toggleModal}
              label={t("select_content")}
              textColor="blue"
              borderColor="blue"
            />
            <div className="selected-content-badges mb-3">
              {selectedContent.map((content) => (
                <Badge color="primary" key={content.id} className="me-2">
                  {content.title}
                </Badge>
              ))}
            </div>

            <div className="text-end">
              <SecondaryButton
                type="submit"
                label={t("add_thread")}
                icon={FaPlus}
              />
            </div>
          </Form>
        </div>
      </Collapse>
      <ContentModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onContentSelect={handleContentSelect}
      />
    </div>
  );
};

export default NewDialogForm;
